<template>
  <v-row justify="center">
    <v-col cols="12" md="12" lg="10" xl="8">
      <v-sheet class="pa-5" border>
        <div v-if="false">
          <h4 class="text-red-darken-4 mt-4">
            <strong>Trwa przerwa konserwacyjna. Zakończenie prac godz. 19:30</strong>
          </h4>
        </div>

        <div v-else>
          <!--

          <p class="text-subtitle-1 font-weight-medium mt-4">
            Rekrutacja do Bednarskiej Szkoły Realnej w roku 2024<br>
            Zapisy będą trwały od <strong>10 stycznia</strong> do <strong>27 lutego do godz. 24:00</strong>
          </p>
          -->

          <p class="text-subtitle-1 font-weight-medium mt-4">
            Rekrutacja do Bednarskiej Szkoły Realnej w roku 2024<br>
            <!-- Zapisy zostały zakończone <strong>27 lutego</strong> -->
          </p>

          <div class="text-error mt-4 font-weight-bold">Uwaga!</div>          
          <p class="text-primary font-weight-bold">
            <!-- Informacje o miejscu i godzinie poszczególnych egzaminów dostępne są po zalogowaniu się do systemu. -->
            <!-- Uprzejmie informujemy, że wyniki rekrutacji ogłosimy w piątek 19 kwietnia do godziny 12.00. <br>Będą dostępne po zalogowaniu się do systemu -->
            Wyniki rekrutacji są już dostępne po zalogowaniu się do systemu.
          </p>

          <div class="mt-6">
            <btn-cancel :disabled="applyState != 1" to="/register">Zarejestruj się</btn-cancel>
            <btn-action :disabled="applyState == 0" to="/login" class="ml-4">Zaloguj się</btn-action>
          </div>
        </div>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script setup>
import BtnAction from '@/components/shared/UI/BtnAction.vue';
import BtnCancel from '@/components/shared/UI/BtnCancel.vue';

import { settings } from '@/config'

const applyState = settings.applyState
</script>
